import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, Link } from 'react-router-dom';
import axios from 'axios';

// Custom components
import Menu from '../../components/Menu/Menu';
import Profile from '../../components/profile/Profile';

const { REACT_APP_SUPPORT_API } = process.env;

const DefaultLayout = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [permissions, setPermissions] = useState([]);

    const triggerHandler = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const logoutHandler = () => {
        setIsLoggedIn(false);
        setIsMenuOpen(!isMenuOpen);
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
    }

    // Effect to get permissions
    useEffect(() => {
        const getPermissions = () => {
            const userId = localStorage.getItem('userId');
            setIsLoading(true);
            axios.get(`${REACT_APP_SUPPORT_API}user/${userId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                setPermissions(response.data.permissions);
                setIsLoading(false);
            },
            (error) => {
                if (error.code) {
                    if (error.code === 'ERR_NETWORK') {
                        setIsLoggedIn(false);
                    }
                }
                setIsLoading(false);
            })
        };

        getPermissions()
    }, []);

    return (
        <div>
            {!isLoggedIn && (
                <Navigate to="/login" />
            )} 
            <>
                <div
                    className={isMenuOpen ? "defaultLayout_overlay active" : "defaultLayout_overlay"}
                    onClick={triggerHandler}
                />
                <Menu 
                    triggerHandler={triggerHandler}
                    isMenuOpen={isMenuOpen}
                    logoutHandler={logoutHandler}
                    permissions={permissions}
                />

                <Profile
                    logoutHandler={logoutHandler}
                />

                <div className="defaultLayout__content">    
                    <Outlet context={[permissions]} />
                </div>
            </>
        </div>
    );
  };
  
  export default DefaultLayout;
