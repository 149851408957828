import { useState } from 'react';
import {
    TEDropdown,
    TEDropdownToggle,
    TEDropdownMenu,
    TEDropdownItem,
    TERipple,
} from "tw-elements-react";

// Custom components
import Modal from "../../components/Modal/Modal";
import Toggle from "../../components/Toggle/Toggle";

// Custom styles
import './userManagement.scss';

const UserManagementCard = (props) => {
    // Props
    const { 
        allPermissions,
        cardUI,
        groupId,
        groupUpdateHandler,
        modalHandler,
        permissionData,
        saveGroupHandler,
        removeUserFromGroup,
        saveUser,
        title,
        userData,
        usersNoPermissions
    } = props;

    // State
    const [removeUser, setRemoveUser] = useState([]);
    const [userToBeAdded, setUserToBeAdded] = useState({});

    let outerClass = "relative block overflow-hidden rounded-lg border bg-white mt-4 userManagementCard__wrapper";
    let headerClass = "bg-blue-400 p-1 userManagementCard__header";

    const toggleHandler = (id) => {
        groupUpdateHandler(groupId, id);
    }

    const toggleModalHandler = (id, modal) => {
        modalHandler(cardUI.id, modal);
    }

    const saveGroup = () => {
        saveGroupHandler(groupId, cardUI.id)
    }

    const removeUserConfirmHandler = (id) => {
        setRemoveUser([...removeUser, id]);
    }

    const cancelRemoveUserHandler = (id) => {
        const newRemoveUserArr = [...removeUser];
        const index = newRemoveUserArr.indexOf(id);
        newRemoveUserArr.splice(index, 1);

        setRemoveUser(newRemoveUserArr);
    }

    const removeUserFromGroupHandler = (id) => {
        const newRemoveUserArr = [...removeUser];
        const index = newRemoveUserArr.indexOf(id);
        newRemoveUserArr.splice(index, 1);

        setRemoveUser(newRemoveUserArr);

        removeUserFromGroup(id, groupId, cardUI.id);
    }

    const addUserToGroupStateHandler = (user) => {
        setUserToBeAdded(user);
    }

    const saveUserHandler = () => {
        saveUser(userToBeAdded.id, groupId, cardUI.id);
    }

    const renderUsersNoPermissions = () => {
        const mapUsers = usersNoPermissions.map((user) => {
            return (
                <TEDropdownItem
                    key={user.id}
                >
                    <span
                        className="block w-full min-w-[160px] cursor-pointer whitespace-nowrap bg-transparent px-4 py-2 text-sm text-left font-normal pointer-events-auto text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:bg-neutral-100 focus:bg-neutral-100 focus:text-neutral-800 focus:outline-none active:no-underline dark:text-neutral-200 dark:hover:bg-neutral-600 dark:focus:bg-neutral-600 dark:active:bg-neutral-600"
                        onClick={() => addUserToGroupStateHandler(user)}
                    >
                        {user.name}
                    </span>
                </TEDropdownItem>
            )
        })

        return (
            <div>
                <TEDropdown
                    className="flex justify-center"
                >
                    <TERipple rippleColor="light">
                        <TEDropdownToggle
                            className="flex items-center whitespace-nowrap rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] motion-reduce:transition-none dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                            Select User
                            <span className="ml-2 [&>svg]:w-5 w-2">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </span>
                        </TEDropdownToggle>
                    </TERipple>

                    <TEDropdownMenu>
                        {mapUsers}
                    </TEDropdownMenu>
                </TEDropdown>

                <span>{userToBeAdded.name}</span>
            </div>
        )
    }

    const renderPermissions = () => {
        const permissions = allPermissions.map((permission) => {
            let toggleActive = false;
            if (permissionData.includes(permission.id)) {
                toggleActive = true;
            } 

            return (
                <div
                    className="flex items-center justify-between pl-2 pr-2 userManagementCard__permissionWrapper"
                    key={permission.id}
                >
                    <div>{permission.name}</div>
                    <div>
                        <Toggle
                            toggleHandler={toggleHandler}
                            toggleActive={toggleActive}
                            value={permission.id}
                        />
                    </div>
                </div>
        )})

        return permissions;
    }

    const renderUsers = () => {
        const users = userData.map((user) => (
            <div
                className="flex items-center justify-between pl-2 pr-2"
                key={user.id}
            >
                <div>{user.name}</div>
                {(removeUser.includes(user.id)) ? (
                    <div className="userManagementCard__confirmButtonWrapper">
                        <span onClick={() => cancelRemoveUserHandler(user.id)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="#053259"
                                className="w-5 h-5"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </span>
                        <span onClick={() => removeUserFromGroupHandler(user.id)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="#053259"
                                className="w-5 h-5"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </span>
                    </div>
                ) : (
                    <div
                        onClick={() => removeUserConfirmHandler(user.id)}
                        className="userManagementCard__removeButton"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="#ba112e"
                            className="w-5 h-5"
                        >
                            <path
                                fillRule="evenodd"
                                d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </div>
                )}
            </div>
        ))

        return users;
    }

    return (
        <div className={outerClass}>
            <div className={headerClass}>
                <h4 className="font-semibold text-center text-white">{title}</h4>
            </div>

            {renderUsers()}

            {groupId !== 2 && (
                <div className="userManagementCard__buttonWrapper">
                    <TERipple rippleColor="light">
                        <span
                            onClick={() => modalHandler(cardUI.id, 'userModalIsOpen')}
                        >
                            Add User
                        </span>
                    </TERipple>

                    <TERipple rippleColor="light">
                        <span
                            onClick={() => modalHandler(cardUI.id, 'permissionModalIsOpen')}
                        >
                            View Permissions
                        </span>
                    </TERipple>
                </div>
            )}

            {/* Add Users modal */}
            <Modal
                isModalOpen={cardUI.userModalIsOpen}
                setIsModalOpen={() => toggleModalHandler(cardUI.id, 'userModalIsOpen')}
                title="Add User to Group"
                body={renderUsersNoPermissions()}
                buttonHandler={saveUserHandler}
                buttonText="Add User"
                isLoading={cardUI.isLoading}
            />

            {/* Permissions modal */}
            <Modal
                isModalOpen={cardUI.permissionModalIsOpen}
                setIsModalOpen={() => toggleModalHandler(cardUI.id, 'permissionModalIsOpen')}
                title="Group Permissions"
                body={renderPermissions()}
                buttonHandler={saveGroup}
                buttonText="Update Permissions"
                isLoading={cardUI.isPermissionsLoading}
            />

        </div>
    )
}

export default UserManagementCard;

