import { useState } from 'react';

// custom styles
import './toggle.scss';

const Toggle = (props) => {
    const {
        toggleActive,
        toggleHandler,
        value
    } = props;

    return (
        <div
            className={toggleActive ? "toggle active" : "toggle"}
            onClick={() => toggleHandler(value)}
        >
            <div className="toggle__ball"></div>
        </div>
    )
}

export default Toggle;