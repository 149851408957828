import { useState } from 'react';
import {
    TERipple,
    TEDropdown,
    TEDropdownItem,
    TEDropdownToggle,
    TEDropdownMenu,
} from "tw-elements-react";
import axios from 'axios';

// Custom styles
import './userManagement.scss';
import Modal from '../../components/Modal/Modal';

// Env
const { REACT_APP_SUPPORT_API } = process.env;

const UserManagementDeleteUser = (props) => {
    // Props
    const {
        reloadData,
        toastHandler,
        users
    } = props;

    // State
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    const toggleModalHandler = () => {
        setIsModalOpen(!isModalOpen);
    }

    const onSelectHander = (user) => {
        setUser(user);
    }

    const deleteUserHandler = () => {
        setIsLoading(true);
        axios.delete(`${REACT_APP_SUPPORT_API}user/${user.id}`,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
        .then((response) => {
            setIsLoading(false);
            toggleModalHandler();
            toastHandler(true, 'User removed successfully', false);
            reloadData();
        },
        (error) => {
            console.log(error);
            setIsLoading(false);
            toggleModalHandler();
            if (error.code) {
                if (error.code === 'ERR_NETWORK') {
                    setIsLoggedIn(false);
                }
            }
            
            if (error.response.data.message) {
                toastHandler(true, error.response.data.message, true);
            } else {
                toastHandler(true, 'Unable to delete user', true);
            }
        })
    }

    const renderRemoveUserBody = () => {
        const mapUsers = users.map((user) => {
            return (
                <TEDropdownItem
                    key={user.id}
                >
                    <span
                        className="block w-full min-w-[160px] cursor-pointer whitespace-nowrap bg-transparent px-4 py-2 text-sm text-left font-normal pointer-events-auto text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:bg-neutral-100 focus:bg-neutral-100 focus:text-neutral-800 focus:outline-none active:no-underline dark:text-neutral-200 dark:hover:bg-neutral-600 dark:focus:bg-neutral-600 dark:active:bg-neutral-600"
                        onClick={() => onSelectHander(user)}
                    >
                        {user.name}
                    </span>
                </TEDropdownItem>
            )
        })

        return (
            <div>
                <TEDropdown
                    className="flex justify-center"
                >
                    <TERipple rippleColor="light">
                        <TEDropdownToggle
                            className="flex items-center whitespace-nowrap rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] motion-reduce:transition-none dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                            Select User
                            <span className="ml-2 [&>svg]:w-5 w-2">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </span>
                        </TEDropdownToggle>
                    </TERipple>

                    <TEDropdownMenu>
                        {mapUsers}
                    </TEDropdownMenu>
                </TEDropdown>
                <div className="userManagement__deleteUser">
                    <span>{user.name}</span>
                </div>
                
            </div>
        )
    }

    return (
        <div>
             <TERipple rippleColor="light">
                <span
                    className="userManagementAddUser__button"
                    onClick={toggleModalHandler}
                >
                    Delete User
                </span>
            </TERipple>

            <Modal
                isModalOpen={isModalOpen}
                setIsModalOpen={toggleModalHandler}
                title="Delete User"
                body={renderRemoveUserBody()}
                buttonHandler={deleteUserHandler}
                buttonText="Delete User"
                isLoading={isLoading}
            />
        </div>
    )
}

export default UserManagementDeleteUser;