import React, { useState } from 'react';
import axios from 'axios';
import {
    TEModal,
    TEModalContent,
    TEModalDialog,
    TEModalHeader,
    TEModalFooter,
    TERipple,
    TETextarea,
    TEModalBody,
} from 'tw-elements-react';

// Custom styles
import './markManyAsDelivered.scss';

const { REACT_APP_SUPPORT_API } = process.env;

const MarkManyAsDelivered = (props) => {
    // Props
    const {
        toastHandler,
    } = props;

    // State
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [orderIds, setOrderIds] = useState("");
    const [feedback, setFeedback] = useState([]);

    // Functions
    const toggleModal = () => {
        setIsOpen(!isOpen);
    }
    
    const fixPnQueue = () => {
        setIsLoading(true);
        // Convert string of order ids into array.
        const orderIdsArr = orderIds.split(",");
        axios({
            method: 'post',
            url: `${REACT_APP_SUPPORT_API}general/many_mark_delivered`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            data: {
                'user_id': localStorage.getItem('userId'),
                'order_ids': orderIdsArr
            }
        })
        .then((response) => {
            setFeedback(response.data.feedback);
            // setIsOpen(false);
            setIsLoading(false);
            // toastHandler(true, 'EDI Queue fixed successfully');
        },
        (error) => {
            setIsOpen(false);
            setIsLoading(false);
            if (error.response.data) {
                const message = error.response.data.message;
                toastHandler(true, message);
            } else {
                toastHandler(true, 'There was a problem trying to fix the EDI Queue');
            }
        });
    }

    const resetFormHandler = () => {
        setFeedback([]);
        setOrderIds("");
    }

    const renderFeedback = () => {
        const feedbackMap = feedback.map((item) => (
            <tr>
                <td>{item.order_id}</td>
                <td>{item.message}</td>
            </tr>
        ))

        return feedbackMap;
    }

    return (
        <div>
            <TERipple rippleColor="white">
                <button
                    type="button"
                    className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] button__primary"
                    onClick={toggleModal}
                >
                    Mark orders as Delivered
                </button>
            </TERipple>

            <TEModal
                show={isOpen}
                setShow={toggleModal}
            >
                <TEModalDialog>
                    <TEModalContent
                        className='cancelOrder__modal'
                    >
                        <TEModalHeader>
                            {/* <!--Modal title--> */}
                            <h5 className="text-xl font-medium leading-normal">
                                Mark orders as Delivered
                            </h5>
                            {/* <!--Close button--> */}
                            <button
                                type="button"
                                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                onClick={() => setIsOpen(!isOpen)}
                                aria-label="Close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                                </svg>
                            </button>
                        </TEModalHeader>

                        <TEModalBody>
                            <h4>Enter the order ids you with to mark as delivered, separated by a comma</h4>
                            <TETextarea
                                value={orderIds}
                                className='markManyAsDelivered__textArea'
                                id="textareaExample"
                                label="Enter Order IDs"
                                rows={8}
                                theme={{
                                    activeLabel: {
                                        "color": "green"
                                    }
                                }}
                                onChange={(e) => setOrderIds(e.target.value)}
                            />

                            {feedback.length > 0 && (
                                <table className="markManyAsDelivered__table">
                                    <tbody>
                                        {renderFeedback()}    
                                    </tbody>
                                </table>
                            )}
                        </TEModalBody>

                        <TEModalFooter>
                            {feedback.length > 0 ? (
                                <>
                                    <TERipple rippleColor="light">
                                <button
                                    type="button"
                                    className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200 button__secondary"
                                    onClick={() => setIsOpen(!isOpen)}
                                >
                                    Close
                                </button>
                            </TERipple>

                            <TERipple rippleColor="light">
                                <button
                                    type="button"
                                    className="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] button__primary markManyAsDelivered__button"
                                    onClick={resetFormHandler}
                                >
                                    {isLoading ? (
                                        <span
                                            className="inline-block h-5 w-5 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-6px] motion-reduce:animate-[spin_1.5s_linear_infinite] ediQueueFix__spinner"
                                            role="status"
                                        >
                                            <span
                                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                            >
                                                Loading...
                                            </span>
                                        </span>
                                    ) : (
                                        <span>Mark More as Delivered</span>
                                    )}
                                </button>
                            </TERipple>
                                </>
                            ) : (
                                <>
                                    <TERipple rippleColor="light">
                                <button
                                    type="button"
                                    className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200 button__secondary"
                                    onClick={() => setIsOpen(!isOpen)}
                                >
                                    Cancel
                                </button>
                            </TERipple>

                            <TERipple rippleColor="light">
                                <button
                                    type="button"
                                    className="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] button__primary markManyAsDelivered__button"
                                    onClick={fixPnQueue}
                                >
                                    {isLoading ? (
                                        <span
                                            className="inline-block h-5 w-5 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-6px] motion-reduce:animate-[spin_1.5s_linear_infinite] ediQueueFix__spinner"
                                            role="status"
                                        >
                                            <span
                                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                            >
                                                Loading...
                                            </span>
                                        </span>
                                    ) : (
                                        <span>Mark as Delivered</span>
                                    )}
                                </button>
                            </TERipple>
                                </>
                            )}
                            
                        </TEModalFooter>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
        </div>
    )
}

export default MarkManyAsDelivered;