import { useEffect, useState } from 'react';
import { Navigate, useOutletContext } from "react-router-dom";
import axios from 'axios';

// Custom components
import Loader from '../../components/Loader/Loader';
import NoAccess from '../../components/NoAccess/NoAccess';
import Toast from '../../components/toast/Toast';
import UserManagementCard from './UserManagementCard';
import UserManagementAddUser from './UserManagementAddUser';
import UserManagementDeleteUser from './UserManagementDeleteUser';

// Env
const { REACT_APP_SUPPORT_API } = process.env;

const UserManagement = () => {
    const [permissions] = useOutletContext();

    // State
    const [data, setData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [allPermissions, setAllPermissions] = useState([]);
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastIsError, setToastIsError] = useState(false);
    const [allUsers, setAllUsers] = useState([]);

    // Card ui handles different UI states for each card
    const [cardUI, setCardUI] = useState([]);

    // Handles the updating of permissions per group.
    const groupUpdateHandler = (groupId, permissionId) => {
        const groupIndex = data.findIndex(e => e.id === groupId);
        const groupData = data[groupIndex];

        if (groupData.permissions.includes(permissionId)) {
            // remove permission
            const index = groupData.permissions.indexOf(permissionId);
            groupData.permissions.splice(index, 1);
        } else {
            // add permission
            groupData.permissions.push(permissionId);
        }

        const newData = [...data];
        newData[groupIndex] = groupData;
        setData(newData);
    }

    const toastHandler = (isOpen, message, isError) => {
        setIsToastOpen(isOpen);
        setToastMessage(message);
        setToastIsError(isError);
    }

    const saveGroupHandler = (groupId, loadingId) => {
        const groupIndex = data.findIndex(e => e.id === groupId);
        const groupData = data[groupIndex];

        // Update load state for this specific card
        const newUIData = [...cardUI];
        const cardIndex = newUIData.findIndex(e => e.id === loadingId);
        newUIData[cardIndex]['isLoading'] = true;
        setCardUI(newUIData);

        axios.post(`${REACT_APP_SUPPORT_API}permissions`, groupData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
        .then((response) => {
            toastHandler(true, 'Group successfully updated', false);
            newUIData[cardIndex]['isLoading'] = false;
            newUIData[cardIndex]['permissionModalIsOpen'] = false; 
            setCardUI(newUIData);
        },
        (error) => {
            if (error.code) {
                if (error.code === 'ERR_NETWORK') {
                    setIsLoggedIn(false);
                }
            }
            toastHandler(true, 'Group failed to update', true);
            newUIData[cardIndex]['isLoading'] = false;
            newUIData[cardIndex]['permissionModalIsOpen'] = false;
            setCardUI(newUIData);
        })
    }

    const removeUserFromGroup = (userId, groupId, uiId) => {
        // Update load state for this specific card
        const newUIData = [...cardUI];
        const cardIndex = newUIData.findIndex(e => e.id === uiId);
        newUIData[cardIndex]['isLoading'] = true;
        setCardUI(newUIData);

        axios.delete(`${REACT_APP_SUPPORT_API}user/${userId}/${groupId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
        .then((response) => {
            toastHandler(true, 'User successfully removed from Group', false);
            newUIData[cardIndex]['isLoading'] = false;
            setCardUI(newUIData);
            // reload data
            getUsers();
        },
        (error) => {
            if (error.code) {
                if (error.code === 'ERR_NETWORK') {
                    setIsLoggedIn(false);
                }
            }
            toastHandler(true, 'Failed to remove user from Group', true);
            newUIData[cardIndex]['isLoading'] = false;
            setCardUI(newUIData);
        })
    }

    const saveUser = (userId, groupId, uiId) => {
        // Update load state for this specific card
        const newUIData = [...cardUI];
        const cardIndex = newUIData.findIndex(e => e.id === uiId);
        newUIData[cardIndex]['isLoading'] = true;
        setCardUI(newUIData);

        axios.post(`${REACT_APP_SUPPORT_API}user/${userId}/${groupId}`, "",  {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
        .then((response) => {
            toastHandler(true, 'User successfully added to Group', false);
            newUIData[cardIndex]['isLoading'] = false;
            newUIData[cardIndex]['userModalIsOpen'] = false;
            setCardUI(newUIData);
            // Reload data
            getUsers();
        },
        (error) => {
            console.log(error);
            if (error.code) {
                if (error.code === 'ERR_NETWORK') {
                    setIsLoggedIn(false);
                }
            }
            toastHandler(true, 'Failed to add user to Group', true);
            newUIData[cardIndex]['isLoading'] = false;
            newUIData[cardIndex]['userModalIsOpen'] = false;
            setCardUI(newUIData);
        })
    }
    
    const modalHandler = (index, modal) => {
        // Update load state for this specific card
        const newUIData = [...cardUI];
        const cardIndex = newUIData.findIndex(e => e.id === index);
        newUIData[cardIndex][modal] = !newUIData[cardIndex][modal];
        setCardUI(newUIData);
    }

    const renderGroupCards = () => {
        const groups = data.map((group, index) => (
            <UserManagementCard
                groupId={group.id}
                groupUpdateHandler={groupUpdateHandler}
                title={group.name}
                permissionData={group.permissions}
                userData={group.user_details}
                key={group.id}
                allPermissions={allPermissions}
                saveGroupHandler={saveGroupHandler}
                cardUI={cardUI[index]}
                modalHandler={modalHandler}
                usersNoPermissions={userData}
                removeUserFromGroup={removeUserFromGroup}
                saveUser={saveUser}
            />
        ))

        return groups;
    }

    const getUsers = () => {
        setIsLoading(true);
        axios.get(`${REACT_APP_SUPPORT_API}users`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then((response) => {
            setData(response.data.groups);
            setUserData(response.data.users_without_permissions);
            setAllUsers(response.data.all_users);
            // Create an array for the ui state for each card
            const cardUIArr = []
            for (let x = 0; x < response.data.groups.length; x++) {
                cardUIArr[x] = {
                    id: x,
                    isPermissionsLoading: false,
                    isUsersLoading: false,
                    permissionModalIsOpen: false,
                    userModalIsOpen: false,
                };
            }

            setCardUI(cardUIArr);

            // Get all permissions
            axios.get(`${REACT_APP_SUPPORT_API}permissions`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                setIsLoading(false);
                setAllPermissions(response.data.permissions)
            },
            (error) => {
                console.log(error);
                if (error.code) {
                    if (error.code === 'ERR_NETWORK') {
                        setIsLoggedIn(false);
                    }
                }
                setIsLoading(false);
            });
        },
        (error) => {
            if (error.code) {
                if (error.code === 'ERR_NETWORK') {
                    setIsLoggedIn(false);
                }
            }
            setIsLoading(false);
        })
    };

    // Effect to get users, permissions and users without permissions
    useEffect(() => {
        getUsers()
    }, []);

    return (
        <div className="pr-2 pl-2 pt-1 xl:pr-4 xl:pl-4 xl:pt-4">
            <h3 className="text-2xl font-semibold text-center page__header">
                User Management
            </h3>
            
            {(permissions !== null && permissions.includes(3)) ? 
            (
                <>
                    {!isLoggedIn && (
                        <Navigate to="/login" />
                    )}
                    {(isLoading) ? (
                        <Loader />
                    ) : (
                        <div>
                            <div className="userManagement__actionsWrapper">
                                <UserManagementAddUser
                                    reloadData={getUsers}
                                    toastHandler={toastHandler}
                                />

                                <UserManagementDeleteUser
                                    users={allUsers}
                                    reloadData={getUsers}
                                    toastHandler={toastHandler}
                                />
                            </div>
                            
                            {renderGroupCards()}

                            <Toast
                                isOpen={isToastOpen}
                                message={toastMessage}
                                toastHandler={toastHandler}
                                isError={toastIsError}
                            />
                        </div>
                    )}
                </>
            ) : (
                <NoAccess />                
            )}
        </div>
    )
}

export default UserManagement;