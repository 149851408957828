import { useState } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

// Utils
import getScreenWidth from '../../utils/getScreenWidth';

// Custom styles
import './login.scss';

const Login = () => {
    // State
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [ error, setError] = useState('');
    const [submitButtonClass, setSubmitButtonClass] = useState('block w-full rounded-lg px-5 py-3 text-sm font-medium text-white login__submitButton');

    const attemptLogin = (e) => {
        e.preventDefault();
        setIsLoading(true);

        // Resets
        setSubmitButtonClass('block w-full rounded-lg px-5 py-3 text-sm font-medium text-white login__submitButton');
        setError(false);

        axios.post(`${process.env.REACT_APP_TRAILBREAKER}login`, {
            username,
            password
        })
        .then((response) => {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('userId', response.data.user_id);
            setLoggedIn(true);
            setIsLoading(false);
        },
        (error) => {
            setIsLoading(false);
            if (error.response) {
                if (error.response.status === 401) {
                    setError('Invalid credentials');
                } else {
                    setError('There was a problem connecting to the server. Please try again later');    
                }
            } else {
                setError('There was a problem connecting to the server. Please try again later');
            }
        });
    }

    return (
        (loggedIn) ? (
            <Navigate to="/" />
        )
        :
        (
            <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-lg">
                    <div className="login__imgWrapper">
                        <img src="/pick-n-pay-logo.svg" alt="pnp logo" />
                    </div>

                    <form
                        onSubmit={(e) => attemptLogin(e)}
                        className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8 login__form"
                    >
                        <p className="text-center text-lg font-medium">Sign in to Support Dash</p>

                        <div>
                            <label
                                htmlFor="username"
                                className="sr-only"
                            >
                                Username
                            </label>

                            <div className="relative">
                                <input
                                    type="text"
                                    className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                    placeholder="Enter username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>

                        <div>
                            <label
                                htmlFor="password"
                                className="sr-only"
                            >
                                Password
                            </label>

                            <div className="relative">
                                <input
                                    type="password"
                                    className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>

                        <div className={error ? "login__error" : "login__error default" }>
                            <h5>Invalid login credentials</h5>
                        </div>

                        <div className="login__submitWrapper">
                            <button
                                type="submit"
                                className={submitButtonClass}
                            >
                                {(isLoading) ? (
                                    <span
                                        className="inline-block pt-2 h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent border-white motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                        role="status">
                                    </span>
                                ) : (
                                    <span>
                                        Sign In
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        )
    )
}

export default Login;
