import './noAccess.scss';

const NoAccess = () => {
    return (
        <div className="rounded-lg border border-gray-100 bg-white p-4 shadow-sm transition hover:shadow-lg sm:p-6 noAccess__wrapper">
            <div className='noAccess__innerWrapper'>
                <span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24" 
                        strokeWidth={1.5}
                        stroke="#ba112e"
                        className="w-16 h-16">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                            />
                    </svg>
                </span>
                <p>
                    Unfortunately you do not have permissions to view this information. Please contact an admin to grant you access
                </p>
            </div>
        </div>
    )
}

export default NoAccess;