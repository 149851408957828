import { useState } from 'react';
import {
    TERipple,
} from "tw-elements-react";
import axios from 'axios';

// Custom styles
import './userManagement.scss';
import Modal from '../../components/Modal/Modal';

// Env
const { REACT_APP_SUPPORT_API } = process.env;

const UserManagementAddUser = (props) => {
    // Props
    const {
        reloadData,
        toastHandler
    } = props;

    // State
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    const toggleModalHandler = () => {
        setIsModalOpen(!isModalOpen);
    }

    const addUserHandler = () => {
        setIsLoading(true);
        axios.post(`${REACT_APP_SUPPORT_API}user/${userId}`,
        {
            'name': userName
        },
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
        .then((response) => {
            setIsLoading(false);
            toggleModalHandler();
            toastHandler(true, 'User added successfully', false);
            reloadData();
            console.log(response.data);
        },
        (error) => {
            setIsLoading(false);
            toggleModalHandler();
            if (error.code) {
                if (error.code === 'ERR_NETWORK') {
                    setIsLoggedIn(false);
                }
            }
            
            if (error.response.data.message) {
                toastHandler(true, error.response.data.message, true);
            } else {
                toastHandler(true, 'Unable to add user', true);
            }
        })
    }

    const renderAddUserBody = () => {
        return (
            <div>
                <div>
                    <label
                        htmlFor="username"
                        className="sr-only"
                    >
                        User ID
                    </label>

                    <div className="relative">
                        <input
                            type="number"
                            className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                            placeholder="User ID(This is the users Trailbreaker ID)"
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                            disabled={isLoading}
                        />
                    </div>
                </div>

                <div>
                    <label
                        htmlFor="password"
                        className="sr-only"
                    >
                        User name
                    </label>

                    <div className="relative">
                        <input
                            type="text"
                            className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                            placeholder="Enter user name"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            disabled={isLoading}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
             <TERipple rippleColor="light">
                <span
                    className="userManagementAddUser__button"
                    onClick={toggleModalHandler}
                >
                    Add new User
                </span>
            </TERipple>

            <Modal
                isModalOpen={isModalOpen}
                setIsModalOpen={toggleModalHandler}
                title="Add new User"
                body={renderAddUserBody()}
                buttonHandler={addUserHandler}
                buttonText="Add User"
                isLoading={isLoading}
            />
        </div>
    )
}

export default UserManagementAddUser;